import React, { useState, useEffect } from "react";
import AxiosNavigate from "../../../library/axios-navigate";
import { FormProvider, useForm } from "react-hook-form";
import { Header } from "../../../compontents/Header";
import SearchBox from "../../../compontents/Search";
import { PagingFooter } from "../../../compontents/PagingFooter";
import Pagination from "react-js-pagination";
import PagesList from "../../../compontents/PagesList";
import styled from "@emotion/styled";
import { useMutation } from "@tanstack/react-query";
import { getPartnersCompany } from "../../../library/admin-react-query";
import ButtonComponent from "../../../compontents/Button";
import Modal from "../../../compontents/Modal";

import CustomerForm from "./customer-form";
const CustomerPartnersAdmin = () => {
  const methods = useForm();
  const formRef = useForm();

  const [searchTxt, setSearchTxt] = useState(""); // 엔터
  const [searchValue, setSearchValue] = useState(""); // 키보드
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [openedDetail, setOpenedDetail] = useState(false);

  const [partnersInfor, setPartnersInfor] = useState([]);

  // 파트너 상세 정보 가져오기
  const { mutate: getPartnersCompanyMutate, data: getPartnersCompanyData } =
    useMutation({
      mutationKey: ["getPartnersCompany"],
      mutationFn: getPartnersCompany,
      onSuccess: async (data) => {
        setTotalCount(data.count);
      },
      onError: (error) => {
        console.log(error);
      },
    });

  useEffect(() => {
    const items = {
      searchTxt: searchTxt,
      page: page,
      limit: limit,
    };
    getPartnersCompanyMutate(items);
  }, [searchTxt, limit, page]);

  const handleFinish = () => {
    const items = {
      searchTxt: searchTxt,
      page: page,
      limit: limit,
    };
    getPartnersCompanyMutate(items);
    setOpenedDetail(false);
  };
  return (
    <AxiosNavigate>
      <div style={{ paddingBottom: "30px" }}>
        <FormProvider {...methods}>
          <Header>
            <div>고객사별 파트너</div>
            <div style={{ display: "flex", columnGap: "16px" }}>
              <SearchBox
                placeholder={"고객사, ID, 파트너 이름"}
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    e.nativeEvent.isComposing === false
                  ) {
                    if (
                      e.target.value.length > 0 &&
                      e.target.value.trim().length === 0
                    ) {
                      alert("제목을 입력해 주세요");
                      setSearchValue(e.target.value.trim());
                      return;
                    }
                    // handleBoard(null);
                    setSearchTxt(e.target.value);
                    setSearchValue(e.target.value.trim());
                    setPage(1);
                  }
                }}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              ></SearchBox>
            </div>
          </Header>
          <TableContainer>
            <TableWrapper>
              <Table>
                <thead style={{ height: "31px" }}>
                  <tr>
                    <th width={"70px"}>구분</th>
                    <th width={"30%"}>고객사</th>
                    <th>ID</th>
                    <th>파트너 이름</th>
                    <th>리베이트 비율</th>
                    <th>누적 수익</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {getPartnersCompanyData?.list &&
                    getPartnersCompanyData?.list?.map((e, i) => (
                      <tr key={i}>
                        <td
                          style={{
                            width: "70px",
                            minWidth: "70px",
                            verticalAlign: "top",
                          }}
                        >
                          {i + limit * (page - 1) + 1}
                        </td>
                        <td
                          className="table-left"
                          style={{ verticalAlign: "top" }}
                        >
                          <span>
                            {e.corp_nm}
                            {e.withdrawal_dt && (
                              <>
                                <br />
                                <em style={{ color: "red" }}>(탈퇴)</em>
                              </>
                            )}
                          </span>
                        </td>
                        <td style={{ width: "300px", minWidth: "300px" }}>
                          <ListBox>
                            {e.partners_info.map((item, i) => (
                              <span key={i} style={{ textAlign: "left" }}>
                                {item.partners_id}
                              </span>
                            ))}
                          </ListBox>
                        </td>
                        <td style={{ width: "150px", minWidth: "150px" }}>
                          <ListBox>
                            {e.partners_info.map((item, i) => (
                              <span key={i} style={{ textAlign: "left" }}>
                                {item.partners_nm}
                              </span>
                            ))}
                          </ListBox>
                        </td>
                        <td style={{ width: "150px", minWidth: "150px" }}>
                          <ListBox>
                            {e.partners_info.map((item, i) => (
                              <span key={i} style={{ textAlign: "left" }}>
                                {item.rebate_amt}%
                              </span>
                            ))}
                          </ListBox>
                        </td>
                        <td>
                          <ListBox style={{ alignItems: "end" }}>
                            {e.partners_info.map((item, i) => (
                              <span key={i} style={{ textAlign: "right" }}>
                                {item.totalAmt.toLocaleString()}
                              </span>
                            ))}
                          </ListBox>
                        </td>
                        <td style={{ width: "150px", minWidth: "150px" }}>
                          <ButtonComponent
                            style={{
                              backgroundColor: !e.withdrawal_dt
                                ? "#fff"
                                : "#dad9d9",
                              color: "#4A5568",
                              border: "1.125px solid #E2E8F0",
                              height: "32px",
                              width: "60px",
                              margin: "auto",
                            }}
                            onClick={() => {
                              if (e.withdrawal_dt) {
                                alert("탈퇴한 고객사입니다.");
                                return;
                              }
                              setPartnersInfor(e);
                              setOpenedDetail(true);
                            }}
                          >
                            수정
                          </ButtonComponent>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </TableWrapper>
          </TableContainer>
          <PagingFooter>
            <Pagination
              prevPageText={<Icon src="/assets/icons/chevron-left.svg" />}
              nextPageText={<Icon src="/assets/icons/chevron-right.svg" />}
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={totalCount === 0 ? 1 : totalCount}
              pageRangeDisplayed={5}
              hideFirstLastPages={true}
              onChange={(e) => {
                setPage(e);
              }}
            />
            <PagesList
              onChange={(e) => {
                setLimit(e.value);
                setPage(1);
              }}
            ></PagesList>
          </PagingFooter>

          <Modal
            opend={openedDetail}
            closeModal={() => {
              setOpenedDetail(false);
            }}
            header={"고객사 파트너 수정"}
            formRef={formRef}
            okText={"확인"}
            widthCheck={"550px"}
          >
            <CustomerForm
              ref={formRef}
              data={partnersInfor.partners_info}
              data_seq={partnersInfor.corp_seq}
              handleFinish={handleFinish}
            ></CustomerForm>
          </Modal>
        </FormProvider>
      </div>
    </AxiosNavigate>
  );
};

const TableContainer = styled.div`
  padding: 0px 25px;
  margin-top: 28px;
`;

const TableWrapper = styled.div`
  border: 1px solid #cbd5e0;
  border-radius: 8px;
  overflow: hidden;
  overflow-x: auto;
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  th,
  td {
    text-align: center;
    padding: 2px;
    font-size: 13px;
    font-weight: 300;
    border-right: 1px solid #cbd5e0;
    border-bottom: 1px solid #cbd5e0;
    white-space: nowrap;
  }

  th {
    background-color: #f7fafc;
  }

  tr:last-child td {
    border-bottom: none;
  }

  td:first-of-type {
    text-align: center;
  }

  tr {
    height: 31px;
  }
`;
const Icon = styled.img`
  width: 16px;
`;

const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* row-gap: 7px; */
  span {
    display: inline-block;
    width: 100%;
    padding: 4px 0;
    box-sizing: border-box;
    border-bottom: dotted 1px #cbd5e0;
    &:last-child {
      border-bottom: none;
    }
  }
`;
export default CustomerPartnersAdmin;
