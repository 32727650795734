import React, { useEffect, useState } from "react";
import AxiosNavigate from "../../../library/axios-navigate";
import { FormProvider, useForm } from "react-hook-form";
import { Header } from "../../../compontents/Header";
import SearchBox from "../../../compontents/Search";
import { PagingFooter } from "../../../compontents/PagingFooter";
import Pagination from "react-js-pagination";
import PagesList from "../../../compontents/PagesList";
import styled from "@emotion/styled";
import ButtonComponent from "../../../compontents/Button";
import {
  deletePartnersBoard,
  getBoard,
} from "../../../library/admin-react-query";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import SideModal from "../../../compontents/SideModal";
import { Content } from "../../../library/styled-components";
import { handleFilesName } from "../dashboard";
import { handleDownloadFiles } from "../../../bin/common";
import Modal from "../../../compontents/Modal";
import DashBoardPartnersForm from "./partners-dashboard-form";
import { ModalButton } from "../../../compontents/ModalButton";
import { TableStyle } from "../../../library/styled-components";
const DashBoardPartnersAdmin = () => {
  const methods = useForm();
  const formRef = useForm();
  const closeModal = useForm();

  const [searchTxt, setSearchTxt] = useState(""); // 엔터
  const [searchValue, setSearchValue] = useState(""); // 키보드
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(1000);
  const [page, setPage] = useState(1);

  const [openedDetailDashBoard, setOpenedDetailDashBoard] = useState(false);
  const [openedNewDashBoard, setOpenedNewDashBoard] = useState(false);
  const [selectData, setSelectData] = useState({});
  const [getBoardData, setGetBoardData] = useState(null);

  // 퍄트너스 게시글/공지사항 가져오기
  const { mutate: getBoardMutate } = useMutation({
    mutationFn: getBoard,
    mutationKey: ["getBoard"],
    onSuccess: (data) => {
      if (openedDetailDashBoard) {
        setSelectData(
          data.list.length === 0 ? data.noticeList[0] : data.list[0]
        );
      } else {
        setTotalCount(data.totalCount);
        if (limit === 1000) {
          setLimit(20 - data.noticeList.length);
        }
        setGetBoardData(data);
      }
    },
    onError: (error) => {
      if (error.response.data.code !== 401) {
        alert(error.response.data.message);
      }
      throw error;
    },
  });
  // 퍄트너스 게시글/공지사항 삭제
  const { mutate: deletePartnersBoardMutate } = useMutation({
    mutationFn: deletePartnersBoard,
    mutationKey: ["deletePartnersBoard"],
    onSuccess: (data) => {
      if (data.done) {
        setOpenedDetailDashBoard(false);
        getBoardMutate();
      }
    },
    onError: (error) => {
      if (error.response.data.code !== 401) {
        alert(error.response.data.message);
      }
      throw error;
    },
  });

  useEffect(() => {
    if (openedDetailDashBoard) return;
    const items = {
      board_seq: null,
      page: page,
      limit: limit,
      searchTxt: searchTxt,
    };
    getBoardMutate(items);
  }, [page, searchTxt, limit]);

  const renderTextWithLinks = (text) => {
    if (!text) return;
    return text.replaceAll("<a href", "<a target={'_blank'} href");
  };

  // 게시판 수정
  const handleFinishAction = (modify, board = null) => {
    const items = {
      board_seq: board,
      page: page,
      limit: limit,
      searchTxt: searchTxt,
    };
    if (!modify) {
      // 생성시 끝나고 작업
      setSearchTxt("");
      setPage(1);
      getBoardMutate(items);
    } else {
      // 수정시 끝나고 작업
      getBoardMutate(items);
      setOpenedDetailDashBoard(false);
    }
    setOpenedNewDashBoard(false);
  };

  const handleDeleteData = (seq) => {
    const result = window.confirm("정말로 삭제하겠습니까?");
    if (!result) return;
    deletePartnersBoardMutate(seq);
  };

  return (
    <AxiosNavigate>
      <div style={{ paddingBottom: "30px" }}>
        <FormProvider {...methods}>
          <Header>
            <div>공지사항 관리</div>
            <div style={{ display: "flex", columnGap: "16px" }}>
              <SearchBox
                placeholder={"제목 검색"}
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    e.nativeEvent.isComposing === false
                  ) {
                    if (
                      e.target.value.length > 0 &&
                      e.target.value.trim().length === 0
                    ) {
                      alert("제목을 입력해 주세요");
                      setSearchValue(e.target.value.trim());
                      return;
                    }
                    // handleBoard(null);
                    setSearchTxt(e.target.value);
                    setSearchValue(e.target.value.trim());
                    setPage(1);
                  }
                }}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              ></SearchBox>

              <ButtonComponent
                onClick={() => {
                  setOpenedNewDashBoard(true);
                }}
              >
                작성하기
              </ButtonComponent>
            </div>
          </Header>
          <TableStyle>
            <TableWrapper>
              <table>
                <thead>
                  <tr>
                    <th width={"70px"}>NO</th>
                    <th width={"80%"}>제목</th>
                    <th>작성일</th>
                  </tr>
                </thead>
                <tbody>
                  {getBoardData &&
                    getBoardData.noticeList.map((e, i) => (
                      <tr
                        key={e.board_seq}
                        style={{ backgroundColor: "#f4f4f4" }}
                      >
                        <td>공지</td>
                        <td
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <span
                            style={{
                              whiteSpace: "break-spaces",
                              textAlign: "left",
                            }}
                          >
                            {e.subject}
                          </span>
                          <ButtonComponent
                            style={{
                              backgroundColor: "#fff",
                              color: "#4A5568",
                              border: "1.125px solid #E2E8F0",
                              height: "32px",
                              width: "68px",
                              marginLeft: "8px",
                            }}
                            onClick={(event) => {
                              event.preventDefault();
                              setOpenedDetailDashBoard(true);
                              const items = {
                                board_seq: e.board_seq,
                                page: page,
                                limit: limit,
                                searchTxt: searchTxt,
                              };
                              getBoardMutate(items);
                            }}
                          >
                            {" "}
                            {"상세보기"}
                          </ButtonComponent>
                        </td>
                        <td>
                          {moment.utc(e?.crt_dt).format("YYYY년 MM월 DD일")}
                        </td>
                      </tr>
                    ))}
                  {getBoardData &&
                    getBoardData.list.map((e, i) => (
                      <tr key={e.board_seq}>
                        <td>{i + limit * (page - 1) + 1}</td>
                        <td
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <span
                            style={{
                              whiteSpace: "break-spaces",
                              textAlign: "left",
                            }}
                          >
                            {e.subject}
                          </span>
                          <ButtonComponent
                            style={{
                              backgroundColor: "#fff",
                              color: "#4A5568",
                              border: "1.125px solid #E2E8F0",
                              height: "32px",
                              width: "68px",
                              marginLeft: "8px",
                            }}
                            onClick={(event) => {
                              event.preventDefault();
                              setOpenedDetailDashBoard(true);
                              const items = {
                                board_seq: e.board_seq,
                                page: page,
                                limit: limit,
                                searchTxt: searchTxt,
                              };
                              getBoardMutate(items);
                            }}
                          >
                            {" "}
                            {"상세보기"}
                          </ButtonComponent>
                        </td>
                        <td>
                          {moment.utc(e?.crt_dt).format("YYYY년 MM월 DD일")}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </TableWrapper>
          </TableStyle>
          <PagingFooter>
            <Pagination
              prevPageText={<Icon src="/assets/icons/chevron-left.svg" />}
              nextPageText={<Icon src="/assets/icons/chevron-right.svg" />}
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={totalCount === 0 ? 1 : totalCount}
              pageRangeDisplayed={5}
              hideFirstLastPages={true}
              onChange={(e) => {
                setPage(e);
                // handleBoard();
              }}
            />
            <PagesList
              onChange={(e) => {
                setLimit(e.value - getBoardData.noticeList.length);
                setPage(1);
                // handleBoard();
              }}
            ></PagesList>
          </PagingFooter>

          <SideModal
            opend={openedDetailDashBoard}
            closeModal={() => setOpenedDetailDashBoard(false)}
            header={"공지사항 상세"}
            style={{ maxWidth: "800px" }}
          >
            <Side>
              <Content>
                <figure>
                  <h6>등록일</h6>
                  <h5>
                    {moment.utc(selectData.crt_dt).format("YYYY년 MM월 DD일")}
                  </h5>
                </figure>
                <figure>
                  <h6>제목</h6>
                  <h5>{selectData?.subject}</h5>
                </figure>
                <figure style={{ alignItems: "stretch" }}>
                  <h6>내용</h6>
                  <h5
                    className="jodit-wysiwyg"
                    dangerouslySetInnerHTML={{
                      __html: renderTextWithLinks(selectData?.content),
                    }}
                  ></h5>
                </figure>
                <figure style={{ alignItems: "stretch" }}>
                  <h6>첨부 파일</h6>
                  <div>
                    <h5
                      onClick={async (event) => {
                        event.preventDefault();
                        await handleDownloadFiles(
                          selectData?.attachment_01,
                          handleFilesName(selectData?.attachment_01)
                        );
                      }}
                    >
                      {handleFilesName(selectData?.attachment_01)}
                    </h5>
                    <h5
                      onClick={async (event) => {
                        event.preventDefault();
                        await handleDownloadFiles(
                          selectData?.attachment_02,
                          handleFilesName(selectData?.attachment_02)
                        );
                      }}
                    >
                      {handleFilesName(selectData?.attachment_02)}
                    </h5>
                    <h5
                      onClick={async (event) => {
                        event.preventDefault();
                        await handleDownloadFiles(
                          selectData?.attachment_03,
                          handleFilesName(selectData?.attachment_03)
                        );
                      }}
                    >
                      {handleFilesName(selectData?.attachment_03)}
                    </h5>
                    <h5
                      onClick={async (event) => {
                        event.preventDefault();
                        await handleDownloadFiles(
                          selectData?.attachment_04,
                          handleFilesName(selectData?.attachment_04)
                        );
                      }}
                    >
                      {handleFilesName(selectData?.attachment_04)}
                    </h5>
                    <h5
                      onClick={async (event) => {
                        event.preventDefault();
                        await handleDownloadFiles(
                          selectData?.attachment_05,
                          handleFilesName(selectData?.attachment_05)
                        );
                      }}
                    >
                      {handleFilesName(selectData?.attachment_05)}
                    </h5>
                  </div>
                </figure>
              </Content>

              <div style={{ display: "grid" }} className={"grid"}>
                <ModalButton
                  onClick={() => {
                    handleDeleteData(selectData.board_seq);
                  }}
                >
                  삭제
                </ModalButton>
                <ModalButton
                  onClick={() => {
                    setOpenedNewDashBoard(true);
                  }}
                >
                  수정
                </ModalButton>
              </div>
            </Side>
          </SideModal>

          <Modal
            opend={openedNewDashBoard}
            closeModal={() => {
              setOpenedNewDashBoard(closeModal.current);
              closeModal.current = false;
            }}
            header={"공지사항 작성"}
            formRef={formRef}
            cancelText={""}
            okText={"확인"}
            widthCheck={"950px"}
            //heightCheck={'950px'}
            // style={{minHeight: '950px', maxHeight: 'auto'}}
          >
            <DashBoardPartnersForm
              ref={formRef}
              data={selectData || ""}
              modify={openedDetailDashBoard}
              finish={handleFinishAction}
            ></DashBoardPartnersForm>
          </Modal>
        </FormProvider>
      </div>
    </AxiosNavigate>
  );
};

const Side = styled.div`
  //height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding-bottom: 20px; */
  box-sizing: border-box;

  .grid {
    grid-template-columns: repeat(2, 100px);
    margin-left: auto;
    padding-bottom: 20px;
  }

  > div > figure {
    h6 {
      min-width: 80px;
    }

    a {
      text-decoration: none;
      color: #3279f5;
    }

    h5 p {
      margin: 0;
    }
  }

  > div > figure:last-child {
    div {
      display: grid;
      gap: 5px;
      grid-template-columns: repeat(1, 1fr);
    }

    h5 {
      cursor: pointer;
    }
  }

  > div > figure:nth-of-type(3) {
    h5 {
      width: calc(100% - 80px);

      img {
        width: auto;
        max-width: 100%;
      }
    }
  }

  figure:nth-of-type(3) {
    * {
      width: auto !important;
      max-width: 100% !important;
    }

    table {
      width: 100% !important;
    }
  }
`;
const Button = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  min-height: 32px;
  padding: 0 9px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 6px;
  border: 1.125px solid #e2e8f0;
  background: #fff;
  box-sizing: border-box;
  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  cursor: pointer;

  //    margin-top: 5px;
`;
const TableContainer = styled.div`
  padding: 0px 32px;
  margin-top: 28px;
`;

const TableWrapper = styled.div`
  /* border: 1px solid #CBD5E0;
    border-radius: 8px;
    overflow: hidden;
    overflow-x: auto; */

  th,
  td {
    text-align: center;
  }
`;

const Icon = styled.img`
  width: 16px;
`;
const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  th,
  td {
    text-align: center;
    padding: 8px;
    font-size: 13px;
    font-weight: 300;
    border-right: 1px solid #cbd5e0;
    border-bottom: 1px solid #cbd5e0;
    white-space: nowrap;
  }

  th {
    background-color: #f7fafc;
  }

  tr:last-child td {
    border-bottom: none;
  }

  td:first-of-type {
    text-align: center;
  }

  tr {
    height: 49px;
  }
`;

export default DashBoardPartnersAdmin;
